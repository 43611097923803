import payload_plugin_mPk9tl08Cr from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.1_firebase-admin@12.1.0_encoding@0.1.13__firebase-_qn7ve5luizi5fxbrasjfnyyqgq/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_JnXptnExA8 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_change-case@5.4.4__r2vh4grdn2s5dpjzgwc3qfhhra/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RN1N4BaTQS from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_change-case@5.4.4__r2vh4grdn2s5dpjzgwc3qfhhra/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_YKTRhE5NSX from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_change-case@5.4.4__r2vh4grdn2s5dpjzgwc3qfhhra/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_l7p2c8c7dE from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_rollup@4.17.1_vue@3.4.26_typescript@5.4.5_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import payload_client_034uJmxIBA from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_change-case@5.4.4__r2vh4grdn2s5dpjzgwc3qfhhra/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_rvZC1sNSx4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_change-case@5.4.4__r2vh4grdn2s5dpjzgwc3qfhhra/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_RrbEzjOEeE from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.17.1_typescript@5.4.5_vue@3.4.26_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_naLKiMf2QH from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_change-case@5.4.4__r2vh4grdn2s5dpjzgwc3qfhhra/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/.nuxt/floating-vue.mjs";
import plugin_client_FJ5lOhPWfN from "/vercel/path0/node_modules/.pnpm/nuxt-vuefire@1.0.2_@firebase+app-types@0.9.1_firebase-admin@12.1.0_encoding@0.1.13__firebase-_qn7ve5luizi5fxbrasjfnyyqgq/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/vercel/path0/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/vercel/path0/.nuxt/vuefire-plugin.mjs";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import chunk_reload_client_IdAt16zC0I from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_change-case@5.4.4__r2vh4grdn2s5dpjzgwc3qfhhra/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auto_animate_uTpJuTGsMC from "/vercel/path0/plugins/auto-animate.ts";
import final_modal_aCbWmDLqxD from "/vercel/path0/plugins/final-modal.ts";
import resizable_BtCGBtXSJh from "/vercel/path0/plugins/resizable.ts";
export default [
  payload_plugin_mPk9tl08Cr,
  revive_payload_client_JnXptnExA8,
  unhead_RN1N4BaTQS,
  router_YKTRhE5NSX,
  sentry_client_l7p2c8c7dE,
  payload_client_034uJmxIBA,
  check_outdated_build_client_rvZC1sNSx4,
  plugin_vue3_RrbEzjOEeE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_naLKiMf2QH,
  floating_vue_EIcJ7xiw0h,
  plugin_client_FJ5lOhPWfN,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  unocss_MzCDxu9LMj,
  chunk_reload_client_IdAt16zC0I,
  auto_animate_uTpJuTGsMC,
  final_modal_aCbWmDLqxD,
  resizable_BtCGBtXSJh
]