import { Ventilation } from './Ventilation'

export class CaseVentilation {
  constructor (
    public grid?: Ventilation,
    public ventilator?: Ventilation,
    public position?: CaseVentilationPosition,
    public size?: number,
  ) { }

  static fromJson (json: Record<string, any>): CaseVentilation {
    return new CaseVentilation(
      json.grid ? Ventilation.fromJson(json.grid) : undefined,
      json.ventilator ? Ventilation.fromJson(json.ventilator) : undefined,
      json.position,
      json.size,
    )
  }

  public toJson (): Record<string, any> {
    return {
      grid: this.grid ? this.grid.toJson() : undefined,
      ventilator: this.ventilator ? this.ventilator.toJson() : undefined,
      position: this.position,
      size: this.size,
    }
  }

  public get label (): string {
    if (this.ventilator)
      return this.ventilator.label.replaceAll(',', '<br/>')
    return this.grid?.label ?? ''
  }

  public get ventilatorDepth (): number {
    if (this.ventilator)
      return this.ventilator.depth
    return 0
  }

  public get gridDepth (): number {
    if (this.grid)
      return this.grid.depth
    return 0
  }
}

export enum CaseVentilationPosition {
  frontTop = 1,
  frontBottom = 2,
  leftTop = 3,
  leftBottom = 4,
  rightTop = 5,
  rightBottom = 6,
}

export enum CaseVentilationSide {
  front,
  left,
  right,
}
