export class Ventilation {
  constructor (
    public type: VentilationType,
    public ei: number[],
    public frontDepth: number[],
    public frontFill: number[],
    public sideDepth: number[],
    public sideFill: number[],
    public label: string,
    public size: number,
    public depth: number,
    public power: string,
    public orderNumber: number,
    public price: number,
  ) {}

  static fromJson (json: Record<string, any>): Ventilation {
    return new Ventilation(
      json.type,
      json.ei,
      json.front_depth,
      json.front_fill,
      json.side_depth,
      json.side_fill,
      json.label,
      json.size,
      json.depth,
      json.power,
      json.order_number,
      json.price,
    )
  }

  public toJson (): Record<string, any> {
    return {
      type: this.type,
      ei: this.ei,
      front_depth: this.frontDepth,
      front_fill: this.frontFill,
      side_depth: this.sideDepth,
      side_fill: this.sideFill,
      label: this.label,
      size: this.size,
      depth: this.depth,
      power: this.power,
      order_number: this.orderNumber,
      price: this.price,
    }
  }
}

export enum VentilationType {
  grid = 1,
  ventilator = 2,
}
