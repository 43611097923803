import { default as companyExefO5ebyNMeta } from "/vercel/path0/pages/admin/company.vue?macro=true";
import { default as indexj5bBvmrvjTMeta } from "/vercel/path0/pages/admin/customers/detail/[id]/index.vue?macro=true";
import { default as ordersFv8K7WaxsdMeta } from "/vercel/path0/pages/admin/customers/detail/[id]/orders.vue?macro=true";
import { default as indexQ7iazSzn4hMeta } from "/vercel/path0/pages/admin/customers/index.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as _91code_93HVbBn39jvhMeta } from "/vercel/path0/pages/admin/orders/detail/[code].vue?macro=true";
import { default as indexzXLACf6OWSMeta } from "/vercel/path0/pages/admin/orders/index.vue?macro=true";
import { default as _91id_93WdtiyoFGmpMeta } from "/vercel/path0/pages/admin/sales/[id].vue?macro=true";
import { default as indexPxLtsUSYDTMeta } from "/vercel/path0/pages/admin/sales/index.vue?macro=true";
import { default as usersjvZRxTajERMeta } from "/vercel/path0/pages/admin/users.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: companyExefO5ebyNMeta?.name ?? "admin-company",
    path: companyExefO5ebyNMeta?.path ?? "/admin/company",
    meta: companyExefO5ebyNMeta || {},
    alias: companyExefO5ebyNMeta?.alias || [],
    redirect: companyExefO5ebyNMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/company.vue").then(m => m.default || m)
  },
  {
    name: indexj5bBvmrvjTMeta?.name ?? "admin-customers-detail-id",
    path: indexj5bBvmrvjTMeta?.path ?? "/admin/customers/detail/:id()",
    meta: indexj5bBvmrvjTMeta || {},
    alias: indexj5bBvmrvjTMeta?.alias || [],
    redirect: indexj5bBvmrvjTMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/customers/detail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: ordersFv8K7WaxsdMeta?.name ?? "admin-customers-detail-id-orders",
    path: ordersFv8K7WaxsdMeta?.path ?? "/admin/customers/detail/:id()/orders",
    meta: ordersFv8K7WaxsdMeta || {},
    alias: ordersFv8K7WaxsdMeta?.alias || [],
    redirect: ordersFv8K7WaxsdMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/customers/detail/[id]/orders.vue").then(m => m.default || m)
  },
  {
    name: indexQ7iazSzn4hMeta?.name ?? "admin-customers",
    path: indexQ7iazSzn4hMeta?.path ?? "/admin/customers",
    meta: indexQ7iazSzn4hMeta || {},
    alias: indexQ7iazSzn4hMeta?.alias || [],
    redirect: indexQ7iazSzn4hMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/customers/index.vue").then(m => m.default || m)
  },
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93HVbBn39jvhMeta?.name ?? "admin-orders-detail-code",
    path: _91code_93HVbBn39jvhMeta?.path ?? "/admin/orders/detail/:code()",
    meta: _91code_93HVbBn39jvhMeta || {},
    alias: _91code_93HVbBn39jvhMeta?.alias || [],
    redirect: _91code_93HVbBn39jvhMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/orders/detail/[code].vue").then(m => m.default || m)
  },
  {
    name: indexzXLACf6OWSMeta?.name ?? "admin-orders",
    path: indexzXLACf6OWSMeta?.path ?? "/admin/orders",
    meta: indexzXLACf6OWSMeta || {},
    alias: indexzXLACf6OWSMeta?.alias || [],
    redirect: indexzXLACf6OWSMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WdtiyoFGmpMeta?.name ?? "admin-sales-id",
    path: _91id_93WdtiyoFGmpMeta?.path ?? "/admin/sales/:id()",
    meta: _91id_93WdtiyoFGmpMeta || {},
    alias: _91id_93WdtiyoFGmpMeta?.alias || [],
    redirect: _91id_93WdtiyoFGmpMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/sales/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPxLtsUSYDTMeta?.name ?? "admin-sales",
    path: indexPxLtsUSYDTMeta?.path ?? "/admin/sales",
    meta: indexPxLtsUSYDTMeta || {},
    alias: indexPxLtsUSYDTMeta?.alias || [],
    redirect: indexPxLtsUSYDTMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/sales/index.vue").then(m => m.default || m)
  },
  {
    name: usersjvZRxTajERMeta?.name ?? "admin-users",
    path: usersjvZRxTajERMeta?.path ?? "/admin/users",
    meta: usersjvZRxTajERMeta || {},
    alias: usersjvZRxTajERMeta?.alias || [],
    redirect: usersjvZRxTajERMeta?.redirect,
    component: () => import("/vercel/path0/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]