export enum CableHoleType {
  cutout = 'step4.cutout',
  seal = 'step4.seal',
}

export class CableHole {
  constructor (
    public width: number,
    public height: number,
    public top: number,
    public left: number,
    public topPercentage: number,
    public leftPercentage: number,
    public label?: string,
    public price?: number,
    public type?: CableHoleType,
    public position?: CableHolePosition,
    public sealType?: SealHoleType,
  ) {}

  static fromJson (json: Record<string, any>): CableHole {
    return new CableHole(
      json.width,
      json.height,
      json.top,
      json.left,
      json.top_percentage,
      json.left_percentage,
      json.label,
      json.price,
      json.type === 1 ? CableHoleType.cutout : CableHoleType.seal,
      json.position,
      json.seal_type,
    )
  }

  public toJson (): Record<string, any> {
    return {
      width: this.width,
      height: this.height,
      top: this.top,
      left: this.left,
      top_percentage: this.topPercentage,
      left_percentage: this.leftPercentage,
      label: this.label,
      price: this.price,
      type: this.type === CableHoleType.cutout ? 1 : 2,
      position: this.position,
      seal_type: this.sealType,
    }
  }
}

export enum CableHolePosition {
  top,
  topLeft,
  topRight,
  bottom,
  back,
}

export enum SealHoleType {
  circles,
  squares,
}
